import React, { useState, useEffect, useRef } from "react";
import "../Transaction.css";
import { useWalletStore } from "../../../store/useWalletStore";
import Pagination from "../../../components/Pagination/Pagination";

const Wallet = () => {
  const { transactions, fetchTransactions } = useWalletStore();
  const [filterType, setFilterType] = useState("All");
  const [filterStatus, setFilterStatus] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const transactionsPerPage = 10;
  const listRef = useRef(null);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, [transactions, currentPage]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const dateOptions = { month: "short", day: "numeric", year: "numeric" };
    const timeOptions = { hour: "2-digit", minute: "2-digit", hour12: true };
    const formattedDate = date.toLocaleDateString(undefined, dateOptions);
    const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
    return `${formattedDate} ${formattedTime}`;
  };

  const filteredTransactions = (transactions || [])
    .map((transaction) => {
      let type = transaction.type === "credit" ? "Deposit" : "Withdrawal";
      let remarks = transaction.metadata?.approverRemarks || "N/A";

      if (
        transaction.type === "credit" &&
        transaction.metadata?.transaction_type === "system adjustment"
      ) {
        type = "System Adjustment";
        remarks = transaction.metadata?.remarks || "N/A";
      }

      if (
        transaction.type === "credit" &&
        transaction.metadata?.transaction_type === "refund"
      ) {
        type = "Refund";
        remarks = transaction.betId
          ? `Refund on Bet ID ${transaction.betId}`
          : "N/A";
      }

      return {
        ...transaction,
        date: formatDate(transaction.updatedAt),
        type,
        status:
          transaction.status === "approved"
            ? "Approved"
            : transaction.status === "pending"
            ? "Pending"
            : "Declined",
        remarks,
      };
    })
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .filter((transaction) => {
      return (
        (filterType === "All" || transaction.type === filterType) &&
        (filterStatus === "All" || transaction.status === filterStatus)
      );
    });

  const getStatusColor = (status) => {
    switch (status) {
      case "Approved":
        return "#00FF00"; // Blue
      case "Pending":
        return "#FFDF5A"; // Yellow
      case "Declined":
        return "#A2A2A2"; // Gray
      case "Refund":
        return "#A2A2A2"; // Gray
      default:
        return "inherit";
    }
  };

  // Function to filter out 'bet' and 'winner' transaction types
  const excludeBetAndWinnerTransactions = (transactions) => {
    return transactions.filter((transaction) => {
      const transactionType = transaction.metadata?.transaction_type;
      return transactionType !== "bet" && transactionType !== "winner";
    });
  };

  const displayTransactions =
    excludeBetAndWinnerTransactions(filteredTransactions);

  const paginatedTransactions = displayTransactions.slice(
    (currentPage - 1) * transactionsPerPage,
    currentPage * transactionsPerPage
  );

  const totalPages = Math.ceil(
    displayTransactions.length / transactionsPerPage
  );

  return (
    <div className="transaction-tab-container">
      <div className="filter-container">
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          className="filter-dropdown"
        >
          <option value="All">All Types</option>
          <option value="Deposit">Deposit</option>
          <option value="Withdrawal">Withdrawal</option>
          <option value="System Adjustment">System Adjustment</option>
          <option value="Refund">Refund</option>
        </select>

        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
          className="filter-dropdown"
        >
          <option value="All">All Status</option>
          <option value="Approved">Approved</option>
          <option value="Pending">Pending</option>
          <option value="Declined">Declined</option>
        </select>
      </div>

      <div className="transaction-list" ref={listRef}>
        {paginatedTransactions.map((transaction, index) => (
          <div key={index} className="transaction-item">
            <div className="transaction-header">
              <span className="transaction-date">{transaction.date}</span>
              <span className="transaction-status">
                <span className="status-label">Status: </span>
                <span
                  className="status-value"
                  style={{ color: getStatusColor(transaction.status) }}
                >
                  {transaction.status}
                </span>
              </span>
            </div>
            <div className="transac-id">
              {" "}
              <strong>ID:</strong> {transaction.id}
            </div>
            <div className="transaction-details">
              <div className="transaction-details-left">
                {transaction.type === "Deposit" ? (
                  <>
                    <div className="from-gcash">From Gcash:</div>
                    <ul>
                      <li className="transac-details-item">
                        {transaction.metadata?.accountNumber || "N/A"}
                      </li>
                      <li className="transac-details-item">
                        Reference Number:{" "}
                        {transaction.metadata?.referenceNumber || "N/A"}
                      </li>
                    </ul>
                  </>
                ) : (
                  <>
                    <div className="sent-to">Sent to:</div>
                    <ul>
                      <li className="transac-details-item">
                        {transaction.metadata?.accountNumber || "N/A"}
                      </li>
                      <li className="transac-details-item">
                        {" "}
                        Account Name:{" "}
                        {transaction.metadata?.accountName || "N/A"}
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div className="transaction-details-right">
                <div
                  className={`transac-type ${
                    transaction.type === "System Adjustment"
                      ? "system-adjustment"
                      : ""
                  }`}
                >
                  {transaction.type === "System Adjustment"
                    ? "System\nAdjustment"
                    : transaction.type}
                </div>
              </div>
              <>
                <div className="remarks-label">Remarks:</div>
                <ul>
                  <li className="transac-details-item">
                    {transaction.remarks}
                  </li>
                </ul>
              </>
              <div className="transac-amount">
                Amount: <strong>₱{transaction.amount.toFixed(2)}</strong>
              </div>
            </div>
          </div>
        ))}
        <Pagination
          className="wallet-pagination"
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
};

export default Wallet;
