// routes.jsx
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/Login/Login.jsx";
import RacePage from "./pages/Race/Race.jsx";
import PaymentPage from "./pages/Payment/index.jsx";
import AccountPage from "./pages/Account/Account.jsx";
import ProfilePage from "./pages/Account/Profile/index.jsx";
import About from "./pages/Account/About/About.jsx";
import BettingRules from "./pages/Account/BettingRules/BettingRules.jsx";
import TermsCondition from "./pages/Account/TermsCondition/TermsCondition.jsx";
import PrivatePolicy from "./pages/Account/PrivatePolicy/PrivatePolicy.jsx";
import Maintenance from "./pages/Fallbacks/Maintenance/Maintenance.jsx";
import ErrorBoundary from "./pages/Fallbacks/ErrorBoundary/ErrorBoundary.jsx";
import HowToBetPage from "./pages/Tutorials/Tutorial.jsx";
import ProtectedRoute from "./store/ProtectedRoute.jsx";
import TransactionPage from "./pages/Transaction/index.jsx";
import ResultsPage from "./pages/Results/ResultsPage.jsx";
import { MoreGames } from "./pages/MoreGames/MoreGames.jsx";
import Otp from "./pages/Otp/Otp.jsx";

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login/:referralCode?" element={<LoginPage />} />
        <Route
          path="/race"
          element={
            <ProtectedRoute>
              <RacePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transaction"
          element={
            <ProtectedRoute>
              <TransactionPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payment"
          element={
            <ProtectedRoute>
              <PaymentPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <AccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="account/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="results"
          element={
            <ProtectedRoute>
              <ResultsPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="account/about"
          element={
            <ProtectedRoute>
              <About />
            </ProtectedRoute>
          }
        />
        <Route
          path="account/betting-rules"
          element={
            <ProtectedRoute>
              <BettingRules />
            </ProtectedRoute>
          }
        />
        <Route
          path="account/terms-condition"
          element={
            <ProtectedRoute>
              <TermsCondition />
            </ProtectedRoute>
          }
        />
        <Route
          path="account/private-policy"
          element={
            <ProtectedRoute>
              <PrivatePolicy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/maintenance"
          element={
            <ProtectedRoute>
              <Maintenance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/error-boundary"
          element={
            <ProtectedRoute>
              <ErrorBoundary />
            </ProtectedRoute>
          }
        />
        <Route
          path="/how-to-bet"
          element={
            <ProtectedRoute>
              <HowToBetPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/otp"
          exact
          element={
            <ProtectedRoute>
              <Otp />
            </ProtectedRoute>
          }
        />

        {/* <Route
          path="/more-games"
          exact
          element={
            <ProtectedRoute>
              <MoreGames />
            </ProtectedRoute>
          }
        /> */}

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
