import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./BottomNavigation.css";
import BotNavRace from "../../assets/BottomNavigation/BotNavRace.png";
import BotNavPayment from "../../assets/BottomNavigation/BotNavPayment.png";
import BotNavTransaction from "../../assets/BottomNavigation/BotNavTransaction.png";
import BotNavResult from "../../assets/BottomNavigation/BotNavResult.png";
import BotNavAccount from "../../assets/BottomNavigation/BotNavAccount.png";
import BotNavMoreGames from "../../assets/BottomNavigation/BotNavMoreGames.png";
import useEventsStore from "../../store/useEventStore";

const BottomNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("");
  const { activeEvent } = useEventsStore();

  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/race":
        setActiveTab("race");
        break;
      case "/payment":
        setActiveTab("payment");
        break;
      case "/transaction":
        setActiveTab("transaction");
        break;
      case "/results":
        setActiveTab("result");
        break;
      case "/account":
        setActiveTab("account");
        break;
      case "/more-games":
        setActiveTab("more-games");
        break;
      default:
        setActiveTab("");
        break;
    }
  }, [location.pathname]);

  const handleTabClick = (tab, path) => {
    setActiveTab(tab);
    navigate(path);
  };

  return (
    <nav className="bottom-nav">
      {activeEvent && (
        <>
          <div
            className={`nav-item payment ${
              activeTab === "payment" ? "active" : ""
            }`}
            onClick={() => handleTabClick("payment", "/payment")}
          >
            <img src={BotNavPayment} alt="Payment Icon" className="nav-icon" />
          </div>

          <div
            className={`nav-item transaction ${
              activeTab === "transaction" ? "active" : ""
            }`}
            onClick={() => handleTabClick("transaction", "/transaction")}
          >
            <img
              src={BotNavTransaction}
              alt="Transaction Icon"
              className="nav-icon"
            />
          </div>

          <div
            className={`nav-item result ${
              activeTab === "result" ? "active" : ""
            }`}
            onClick={() => handleTabClick("result", "/results")}
          >
            <img src={BotNavResult} alt="Result Icon" className="nav-icon" />
          </div>

          {/* <div
            className={`nav-item more-games ${
              activeTab === "more-games" ? "active" : ""
            }`}
            onClick={() => handleTabClick("more-games", "/more-games")}
          >
            <img
              src={BotNavMoreGames}
              alt="More Games Icon"
              className="nav-icon"
            />
          </div> */}
        </>
      )}
      <div
        className={`nav-item race ${activeTab === "race" ? "active" : ""}`}
        onClick={() => handleTabClick("race", "/")}
      >
        <img src={BotNavRace} alt="Race Icon" className="nav-icon" />
      </div>
      <div
        className={`nav-item account ${
          activeTab === "account" ? "active" : ""
        }`}
        onClick={() => handleTabClick("account", "/account")}
      >
        <img src={BotNavAccount} alt="Account Icon" className="nav-icon" />
      </div>
    </nav>
  );
};

export default BottomNavigation;
