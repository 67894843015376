import React from "react";
import "./PromoDisplay.css";

const PromoDisplay = () => {
  return (
    <div className="dynamic-race-display">
      <div className="animated-text">
        <span className="reg-text">
          Hulaan, Tumaya at Manalo! KOLOR G! Soon dito lang sa Karera King! 🟩
          🟥 🟦
        </span>
        {/* <span className="underline">Sumali na at huwag palampasin ang pagkakataong manalo! 🏆</span>{" "} */}
        {/* {"      "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Add gap here */}
        {/* <span className="reg-text">Promo runs from </span> Feb 23 - Mar 23, */}
      </div>
    </div>
  );
};

export default PromoDisplay;
